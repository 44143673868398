import { Routes, BrowserRouter, Route } from "react-router-dom";
import ShopperHome from '../src/components/shopper-dashboard/shopper-home';
import SellerDemo from '../src/components/seller-dashboard/seller-demo';
import SellerHome from '../src/components/seller-dashboard/seller-home';
import ProductInfo from "./components/shopper-dashboard/product-info";

function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ShopperHome/>} />
                <Route path="/sellerdemo" element={<SellerDemo/>} />
                <Route path="/sellerhome" element={<SellerHome/>} />
                <Route path="/productinfo" element={<ProductInfo/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default MyRoutes;
