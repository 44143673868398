import "../../index.css";
import { Grid, Box, Hidden} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-multi-carousel';
import { useEffect, useState } from "react";

import axios from 'axios';
import 'react-multi-carousel/lib/styles.css';

import Header  from '../header.jsx';
import SearchPanel from "../search-panel";
import WatchlistCard from "../watchlist-card";
import SalesCard from "../sales-card";
import MJ from "../../images/mj.png";
import HM from "../../images/hm.png";
import AE from "../../images/ae.png";
import item1 from "../../images/item.png";
import item2 from "../../images/item-2.png";
import item3 from "../../images/item-3.png";

function ShopperHome() {

    const [ watchedProduct, setWatchedProducts] = useState([]);
    const [ discountedProduct, setDiscountedProducts] = useState([]);
    const [ loading, setLoading] = useState(true);

    /*useEffect(()=>{
        Promise.all([axios.get(`http://localhost:4000/watchlist/getWatchlistByUserId/${'5074048949299942'}`).then(response => {
            console.log(response.data);
            setWatchedProducts(response.data);
        }),
        axios.get(`http://localhost:4000/product/discountedProducts`).then(response=>{
            console.log(response.data);
            setDiscountedProducts(response.data);
        })]).then(()=>{
            setLoading(false);
        })
    }, []);

    const brandIcon = (brandName) =>{
        if(brandName==='American Eagle'){
            return AE;
        }else if(brandName==='Hennes & Mauritz'){
            return HM;
        }else if(brandName==='Marc Jacobs'){
            return MJ;
        }
    }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
    
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
    
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
    
        return array;
    }*/

    const useStyles = makeStyles(theme => ({
        stretch: { height: "100%" },
        item: { display: "flex", flexDirection: "column" } 
    }));
    
    var classes = useStyles();

    /*var watchedProducts = shuffle(watchedProduct).slice(0,5).map((prod)=>{
        let comment = ''
        /*if(el.quantity===0){ comment = 'Sold Out' }
        else if(el.quantity===1){ comment = 'Last Piece' }
        else{ comment = 'Just In' }

        return {
            id: prod.item._id,
            name: prod.item.product_name, 
            brand: brandIcon(prod.item.brand),
            colors: [...new Set([prod.color, ...prod.item.otherColors, prod.item.color])], 
            sizes: [...new Set([prod.size, prod.item.sizes])],
            oldPrice:  `$${prod.item.original_price.toFixed(2)}`,
            newPrice: {text: `$${prod.item.new_price.toFixed(2)}`, inBudget: prod.item.new_price < prod.budget },
            image: prod.item.photos[0],
            discount: prod.item.discount_percent===0 ? '': `${prod.item.discount_percent}% OFF`,
            comment,
            url: prod.item.url
        }
    });

    var discountedProducts = shuffle(discountedProduct).slice(0,5).map((prod)=>{
        let comment = ''
        /*if(el.quantity===0){ comment = 'Sold Out' }
        else if(el.quantity===1){ comment = 'Last Piece' }
        else{ comment = 'Just In' }

        return {
            id: prod._id,
            name: prod.product_name, 
            brand: brandIcon(prod.brand),
            colors: [...new Set([prod.color, ...prod.otherColors])], 
            sizes: [...new Set([...prod.sizes])],
            oldPrice:  `$${prod.original_price.toFixed(2)}`,
            newPrice: {text: `$${prod.new_price.toFixed(2)}` },
            image: prod.photos[0],
            discount: prod.discount_percent===0 ? '': `${prod.discount_percent}% OFF`,
            comment,
            url: prod.url
        }
    });*/

    var watchedProducts = [
        {
            name: 'Floral Modern Top Short',
            brand: AE,
            oldPrice: 100.00, 
            newPrice: 30.00,
            discount: 70,
            image: item1,
            quantity: 1,
            rating: 2,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Pink top with no back',
            brand: MJ,
            oldPrice: 80.00,
            newPrice: 20.00,
            discount: 25,
            image: item2,
            quantity: 10,
            rating: 4,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Floral Modern Top Short',
            brand: AE,
            oldPrice: 100.00,
            newPrice: 100.00,
            discount: 0,
            image: item3,
            quantity: 5,
            rating: 3.5,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Pink top with no back',
            brand: MJ,
            oldPrice: 60.00,
            newPrice: 30.00,
            discount: 50,
            image: item1,
            quantity: 0,
            rating: 2,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Floral Modern Top Short',
            brand: HM,
            oldPrice: 110.00,
            newPrice:10.00,
            discount: 90,
            image: item2,
            quantity: 2,
            rating: 1,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }
    ];

    watchedProducts = watchedProducts.map((el)=>{
        var budget = 40.99
        let comment = ''
        if(el.quantity===0){ comment = 'Sold Out' }
        else if(el.quantity===1){ comment = 'Last Piece' }
        else{ comment = 'Just In' }

        return {
            ...el,
            oldPrice: `$${el.oldPrice.toFixed(2)}`,
            newPrice: {text: `$${el.newPrice.toFixed(2)}`, inBudget: el.newPrice < budget },
            discount: el.discount===0 ? '': `${el.discount}% OFF`,
            comment
        }

    });

    var discountedProducts = watchedProducts.filter((el)=>el.discount!=='')

    const responsive = {
        maxmaxmaxDesktop: {
            breakpoint: { max: 1200, min:  1150},
            items: 3.5
        },
        maxmaxDesktop: {
            breakpoint: { max: 1149, min:  1050},
            items: 3.25
        },
        maxDesktop: {
            breakpoint: { max: 1049, min:  950},
            items: 3
        },
        minDesktop: {
            breakpoint: { max: 949, min:  850},
            items: 2.75
        },
        minminDesktop: {
            breakpoint: { max: 849, min:  750},
            items: 2.5
        },
        maxTablet: {
            breakpoint: { max: 749, min:  650},
            items: 2.25
        },
        minTablet: {
            breakpoint: { max: 649, min: 550 },
            items: 2
        },
        maxMobile: {
            breakpoint: { max: 549, min: 450 },
            items: 1.5
        },
        minMobile: {
            breakpoint: { max: 449, min: 0 },
            items: 1.25
        }
    };

    const responsiveSales = {
        desktop:{
            breakpoint: {max: 5000, min:  1200},
            items: 1.5
        },
        maxTablet: {
            breakpoint: { max: 1199, min:  900},
            items: 1
        },
        minTablet: {
            breakpoint: { max: 899, min: 600 },
            items: 1
        },
        maxMobile: {
            breakpoint: { max: 599, min: 450 },
            items: 1.2
        },
        minMobile: {
            breakpoint: { max: 449, min: 0 },
            items: 1
        }
    };

    
    return (
        <Box className="white-background" sx={{ flexGrow: 1 }}>
            
            <Grid container spacing={6}>

                <Grid container item spacing={3} alignItems="center" id="myHeader">
                    <Header />
                </Grid>

                <Grid container item spacing={3} alignItems="center">
                    <SearchPanel></SearchPanel>
                </Grid>

                <Grid container item spacing={3} alignItems="center">
                    <Grid item xs={12} sm={9.5} textAlign="start">
                        <span className="items-you-are-watching-text">Items you're watching</span>
                    </Grid>

                    <Grid item xs={12} sm={2.5} textAlign="end">
                        <span className="show-all-text">Show All</span>
                    </Grid>
                </Grid>

                <Hidden lgDown>
                    <Grid container item spacing={3} direction="row" alignItems="stretch">

                        {
                            watchedProducts.map((product, i)=>{
                                if(i===4){
                                    return <Hidden key={i}  xlDown >
                                                <Grid className={classes.item} item lg={3} xl={2.4}>
                                                    <WatchlistCard className={classes.stretch} product={product} ></WatchlistCard>
                                                </Grid>
                                            </Hidden>
                                }else{
                                    return <Grid className={classes.item} item key={i} lg={3} xl={2.4}>
                                                <WatchlistCard className={classes.stretch} product={product}></WatchlistCard>
                                            </Grid>
                                }
                                
                            })
                        }
                    </Grid>
                </Hidden>

                <Hidden lgUp >
                    <div className="carousel-container">
                        <Carousel responsive={responsive}>
                                {
                                    watchedProducts.map( (product, i) => 
                                        {
                                            return <Grid className={classes.item} item key={i} xs={11}>
                                                        <WatchlistCard className={classes.stretch} product={product}></WatchlistCard>
                                                    </Grid>
                                        }
                                    )
                                }
                        </Carousel>
                    </div>
                    
                </Hidden>

                <Grid container item spacing={3} alignItems="center">
                    <Grid item xs={12} sm={9.5} textAlign="start">
                        <span className="items-you-are-watching-text">On Sale</span>
                    </Grid>

                    <Grid item xs={12} sm={2.5} textAlign="end" >
                        <span className="show-all-text">Show All</span>
                    </Grid>
                </Grid>

                <Grid container item>
                    <div className="sales-carousel-container">
                        <Carousel responsive={responsiveSales}>
                                {
                                    discountedProducts.map( (product, i) => 
                                    
                                        {
                                            return <Grid item key={i} xs={11.5}>
                                                        <SalesCard product={product} key={i}></SalesCard> 
                                                    </Grid>
                                        }
                                    )
                                }
                        </Carousel>
                    </div>
                </Grid>
            </Grid>

        </Box>
    );
    
}

export default ShopperHome;
