import "../index.css";
import { Grid, Button, Rating, Hidden } from '@mui/material';
import { StarOutline, Star } from '@mui/icons-material';
import WatchlisButtonIcon from '../images/watchlist-button-icon.svg';
import AddToCartButtonIcon from '../images/add-to-cart-button-icon.svg';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function SalesCard(props) {

    const navigate = useNavigate();

    const productInfo = () => {
        navigate('/productinfo');
    } 

    const [ allColors, setAllColors ] = useState(false);
    const [ allSizes, setAllSizes ] = useState(false);

    const AddToCartButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#080028',
        border: '1px solid #080028',
        color: '#FFFFFF',
        borderRadius: '12px',
        width: '100%',
        fontWeight: '600',
        padding: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#080028',
        },
    }));

    const WatchButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#FFFFFF',
        border: '1px solid #080028',
        color: '#080028',
        borderRadius: '12px',
        width: '100%',
        fontWeight: '600',
        padding: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
    }));

    const showAllColors = (allColors)=>{
        if(allColors){
            return props.product.colors.map((color, i)=>{
                    if(i!==0){
                        return <span className="color-icon" style={{background: `${color}`}}></span>
                    }
                })
        }else{
            return <span></span>
        }
    }

    const showAllSizes = (allSizes)=>{
        if(allSizes){
            return props.product.sizes.map((size, i)=>{
                if(i!==0){
                    return <span className="size-icon">{size}</span>
                }
            })
        }else{
            return <span></span>
        }
    }

    return (
                <div className="sales-card-box">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5} >
                            <div className="relative">
                                < Grid container alignItems="center" className="colors-container" >
                                    <span className="color-icon" style={{background: `${props.product.colors[0]}`}} onClick={()=>setAllColors(!allColors)}></span>
                                    {showAllColors(allColors)}
                                </Grid>
                                <Grid container alignItems="center" className="sizes-container">
                                    <span className="size-icon" onClick={()=>setAllSizes(!allSizes)}>{props.product.sizes[0]}</span>
                                    {showAllSizes(allSizes)}
                                </Grid>

                                <img className="product-image-2" src={props.product.image} alt="product-img" />{/**onClick={()=>productInfo()} */}
                            </div>
                            
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <div>
                                <Grid container direction="column" spacing={{ xs: 3, sm: 3, md: 5 }} >

                                    <Grid item >
                                        <Grid container alignItems="center">
                                            <Grid item xs={6} md={12}> <span className="sales-discount">{props.product.discount}</span> </Grid>
                                            <Hidden mdUp>
                                                <Grid item xs={6}><Rating size="small" value={props.product.rating} emptyIcon={<StarOutline fontSize="small" style={{color: '#FAD06E'}}/>} icon={<Star fontSize="small" style={{color: '#FAD06E'}}/>}/></Grid>
                                            </Hidden>
                                        </Grid>
                                            <Grid container>
                                                <Grid item className="product-brand-container">
                                                    <img className="product-brand" src={props.product.brand} alt="product-brand"/>
                                                </Grid>
                                            </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} sm={12} md={9.5}><span className="sales-product-name">{props.product.name}</span></Grid>
                                            <Hidden mdDown>
                                                <Grid item md={2.5}><Rating size="small" value={props.product.rating} emptyIcon={<StarOutline fontSize="small" style={{color: '#FAD06E'}}/>} icon={<Star fontSize="small" style={{color: '#FAD06E'}}/>}/></Grid>
                                            </Hidden>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={6} sm={6} md={2.5}> <span className="sales-new-price">{props.product.newPrice.text}</span> </Grid>
                                            <Grid item xs={6} sm={6} md={9.5}> <span className="sales-original-price">{props.product.oldPrice}</span> </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Hidden mdDown>
                                        <Grid item > <span className="sales-description">{props.product.description}</span> </Grid>
                                    </Hidden>

                                    <Grid container item spacing={{ xs: 2, sm: 2, md: 3 }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <WatchButton startIcon={<img src={WatchlisButtonIcon} alt="watchlist-button-icon"/>} onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')}> Watch</WatchButton>   
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} > 
                                            <AddToCartButton startIcon={<img src={AddToCartButtonIcon} alt="add-to-cart-button-icon"/>} onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')}>Buy Now</AddToCartButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>
        
    );
}

export default SalesCard;
