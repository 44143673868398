import "../../index.css";
import Header  from '../header.jsx';
import WatchlistCard from "../watchlist-card";
import { makeStyles } from '@mui/styles';
import Carousel from 'react-multi-carousel';
import { Grid, Box, Hidden, Rating, Button } from '@mui/material';
import { StarOutline, Star } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ImagesCarousel from 'react-material-ui-carousel'

import 'react-multi-carousel/lib/styles.css';
import MJ from "../../images/mj.png";
import HM from "../../images/hm.png";
import AE from "../../images/ae.png";
import item1 from "../../images/item.png";
import item2 from "../../images/item-2.png";
import item3 from "../../images/item-3.png";
import image1 from "../../images/1image.png";
import image2 from "../../images/2image.png";
import image3 from "../../images/3image.png";
import image4 from "../../images/4image.png";
import image5 from "../../images/5image.png";
import WatchlisButtonIcon from '../../images/watchlist-button-icon.svg';
import AddToCartButtonIcon from '../../images/add-to-cart-button-icon.svg';

function ProductInfo() {

    const navigate = useNavigate();

    const productInfo = () => {
        navigate('/productinfo');
    } 


    const productDetails = {
        name: 'The Tonal Tuckstitch POLO',
        brand: AE,
        rating: 3, 
        oldPrice: 110.00,
        newPrice:10.00,
        discount: 90,
        photos: [image1, image2, image3, image4, image5],
        colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
        sizes: ['M', 'S', 'XS', 'XL', 'L'],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
    }

    const useStyles = makeStyles(theme => ({
        stretch: { height: "100%" },
        item: { display: "flex", flexDirection: "column" } 
    }));

    var classes = useStyles();

    var watchedProducts = [
        {
            name: 'Floral Modern Top Short',
            brand: AE,
            oldPrice: 100.00, 
            newPrice: 30.00,
            discount: 70,
            image: item1,
            quantity: 1,
            rating: 2,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Pink top with no back',
            brand: MJ,
            oldPrice: 80.00,
            newPrice: 20.00,
            discount: 25,
            image: item2,
            quantity: 10,
            rating: 4,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Floral Modern Top Short',
            brand: AE,
            oldPrice: 100.00,
            newPrice: 100.00,
            discount: 0,
            image: item3,
            quantity: 5,
            rating: 3.5,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Pink top with no back',
            brand: MJ,
            oldPrice: 60.00,
            newPrice: 30.00,
            discount: 50,
            image: item1,
            quantity: 0,
            rating: 2,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }, 
        {
            name: 'Floral Modern Top Short',
            brand: HM,
            oldPrice: 110.00,
            newPrice:10.00,
            discount: 90,
            image: item2,
            quantity: 2,
            rating: 1,
            colors: ['#E77EA9', '#FFFFFF', '#DA4E4E', '#FAD06E', '#EF1383'],
            sizes: ['M', 'S', 'XS', 'XL', 'L'],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut nam quisque et ut rhoncus ultrices dui, porttitor. Eget pellentesque interdum morbi.'
        }
    ];

    watchedProducts = watchedProducts.map((el)=>{
        var budget = 40.99
        let comment = ''
        if(el.quantity===0){ comment = 'Sold Out' }
        else if(el.quantity===1){ comment = 'Last Piece' }
        else{ comment = 'Just In' }

        return {
            ...el,
            oldPrice: `$${el.oldPrice.toFixed(2)}`,
            newPrice: {text: `$${el.newPrice.toFixed(2)}`, inBudget: el.newPrice < budget },
            discount: el.discount===0 ? '': `${el.discount}% OFF`,
            comment
        }

    });

    const AddToCartButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#080028',
        border: '1px solid #080028',
        color: '#FFFFFF',
        borderRadius: '12px',
        width: '100%',
        fontWeight: '600',
        padding: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#080028',
        },
    }));

    const WatchButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#FFFFFF',
        border: '1px solid #080028',
        color: '#080028',
        borderRadius: '12px',
        width: '100%',
        fontWeight: '600',
        padding: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
    }));

    const responsive = {
        maxmaxmaxDesktop: {
            breakpoint: { max: 1200, min:  1150},
            items: 3.5
        },
        maxmaxDesktop: {
            breakpoint: { max: 1149, min:  1050},
            items: 3.25
        },
        maxDesktop: {
            breakpoint: { max: 1049, min:  950},
            items: 3
        },
        minDesktop: {
            breakpoint: { max: 949, min:  850},
            items: 2.75
        },
        minminDesktop: {
            breakpoint: { max: 849, min:  750},
            items: 2.5
        },
        maxTablet: {
            breakpoint: { max: 749, min:  650},
            items: 2.25
        },
        minTablet: {
            breakpoint: { max: 649, min: 550 },
            items: 2
        },
        maxMobile: {
            breakpoint: { max: 549, min: 450 },
            items: 1.5
        },
        minMobile: {
            breakpoint: { max: 449, min: 0 },
            items: 1.25
        }
    };

    return (
        <Box className="white-background" sx={{ flexGrow: 1 }}>
            
            <Grid container spacing={6}>

                <Grid container item spacing={3} alignItems="center" id="myHeader">
                    <Header />
                </Grid>

                <Grid container item spacing={4} >
                    <Grid item><span className="little-product-name">{productDetails.name}</span></Grid>
                    <Grid container item xs={12} alignContent="center" spacing={4}>

                        <Hidden smDown>
                            <Grid container item xs={12} sm={12} md={6} spacing={4} >
                                <Grid container item xs={2} spacing={1.5} alignItems="center" >
                                    {
                                        productDetails.photos.map((photo, i)=>{
                                            return <img key={i} className="sub-photos" src={photo}></img>
                                        })
                                    }
                                </Grid>
                                <Grid container item xs={10} >
                                    <img className="main-photo" src={productDetails.photos[0]}></img>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Hidden smUp>
                            <div className="carousel-container">
                                <ImagesCarousel>
                                    {
                                        productDetails.photos.map( (photo, i) => {
                                            return <Grid key={i} container item xs={12}>
                                                        <img className="main-photo" src={photo}></img>
                                                    </Grid>
                                        } )
                                    }
                                </ImagesCarousel>
                            </div>
                        </Hidden>
                        
                        <Grid container item direction="column" xs={12} sm={12} md={6} >
                            <Grid item >
                                <span className="details-discount">{productDetails.discount===0 ? '': `${productDetails.discount}% OFF`}</span>
                            </Grid>
                            <Grid item className="details-product-brand-container">
                                <img className="product-brand" src={productDetails.brand} alt="product-brand"/>
                            </Grid>
                
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={12} md={9.5}><span className="details-product-name">{productDetails.name}</span></Grid>
                                <Hidden mdDown>
                                    <Grid item md={2.5} textAlign="end"><Rating value={productDetails.rating} emptyIcon={<StarOutline style={{color: '#FAD06E'}}/>} icon={<Star style={{color: '#FAD06E'}}/>}/></Grid>
                                </Hidden>
                            </Grid>

                            <Grid container item spacing={{ xs: 2, sm: 2, md: 3 }}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <WatchButton startIcon={<img src={WatchlisButtonIcon} alt="watchlist-button-icon"/>}> Watch</WatchButton>   
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} > 
                                    <AddToCartButton startIcon={<img src={AddToCartButtonIcon} alt="add-to-cart-button-icon"/>}>Buy Now</AddToCartButton>                                        </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item spacing={3} alignItems="center">
                    <Grid item xs={12} sm={9.5} textAlign="start">
                        <span className="items-you-are-watching-text">Similar Products</span>
                    </Grid>

                    <Grid item xs={12} sm={2.5} textAlign="end" >
                        <span className="show-all-text">Show More</span>
                    </Grid>
                </Grid>

                <Hidden lgDown>
                    <Grid container item spacing={3} direction="row" alignItems="stretch">

                        {
                            watchedProducts.map((product, i)=>{
                                if(i===4){
                                    return <Hidden key={i}  xlDown >
                                                <Grid className={classes.item} item lg={3} xl={2.4} onClick={()=>productInfo()}>
                                                    <WatchlistCard className={classes.stretch} product={product} ></WatchlistCard>
                                                </Grid>
                                            </Hidden>
                                }else{
                                    return <Grid className={classes.item} item key={i} lg={3} xl={2.4} onClick={()=>productInfo()}>
                                                <WatchlistCard className={classes.stretch} product={product}></WatchlistCard>
                                            </Grid>
                                }
                                
                            })
                        }
                    </Grid>
                </Hidden>

                <Hidden lgUp >
                    <div className="carousel-container">
                        <Carousel responsive={responsive}>
                                {
                                    watchedProducts.map( (product, i) => 
                                        {
                                            return <Grid className={classes.item} item key={i} xs={11} onClick={()=>productInfo()}>
                                                        <WatchlistCard className={classes.stretch} product={product}></WatchlistCard>
                                                    </Grid>
                                        }
                                    )
                                }
                        </Carousel>
                    </div>
                    
                </Hidden>
            </Grid>
        </Box>
    );
}

export default ProductInfo;
