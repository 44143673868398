import "../index.css";
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function WatchlistCard(props) {

    const navigate = useNavigate();

    const productInfo = () => {
        navigate('/productinfo');
    } 

    const [ allColors, setAllColors ] = useState(false);
    const [ allSizes, setAllSizes ] = useState(false);

    const ColorButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#080028',
        border: '1px solid #000000',
        borderRadius: '12px',
        width: '100%',
        height: '55px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#080028',
        },
    }));

    const inBudget = (price)=>{
        if(price.inBudget) {
            return <span className="text-button" style={{fontSize: "18px", color: '#55C961', fontWeight: "600"}}>{price.text}</span> 
        } else {
            return <span className="text-button" style={{fontSize: "18px", fontWeight: "600"}}>{price.text}</span>
        }
    }

    const isSoldOut = (comment)=>{
        if(comment==='Sold Out') {
            return <span className="product-comment" style={{color: '#DE5233'}}>{comment}</span>
        } else {
            return <span className="product-comment" >{comment}</span>
        }
    }

    const isDiscounted = (discount, price)=>{
        if(discount === '') {
            return <span className="product-price" >{price}</span>
        } else {
            return <span className="product-price" style={{textDecoration: 'line-through'}}>{price}</span>
        }
    }

    const showAllColors = (allColors)=>{
        if(allColors){
            return props.product.colors.map((color, i)=>{
                    if(i!==0){
                        return <span className="color-icon" style={{background: `${color}`}}></span>
                    }
                })
        }else{
            return <span></span>
        }
    }

    const showAllSizes = (allSizes)=>{
        if(allSizes){
            return props.product.sizes.map((size, i)=>{
                if(i!==0){
                    return <span className="size-icon">{size}</span>
                }
            })
        }else{
            return <span></span>
        }
    }

    return (
            <Grid container item spacing={5} alignItems="center"  >
                <Grid item rowSpacing={1}>
                    <Grid xs={12} item className={`relative ${props.product.comment==='Sold Out' ? "opaque" : ""}`}>
                        < Grid container alignItems="center" className="colors-container" >
                            <span className="color-icon" style={{background: `${props.product.colors[0]}`}} onClick={()=>setAllColors(!allColors)}></span>
                            {showAllColors(allColors)}
                        </Grid>
                        <Grid container alignItems="center" className="sizes-container">
                            <span className="size-icon" onClick={()=>setAllSizes(!allSizes)}>{props.product.sizes[0]}</span>
                            {showAllSizes(allSizes)}
                        </Grid>
                        
                        <img className="product-image" src={props.product.image} alt="product-img" />{/**onClick={()=>productInfo()} */}
                    </Grid>
                    <Grid item>
                        {isSoldOut(props.product.comment)}
                    </Grid>
                    <Grid item className="product-brand-container">
                        <img className="product-brand" src={props.product.brand} alt="product-brand"/>
                    </Grid>
                    <Grid item className={`${props.product.comment==='Sold Out' ? "opaque" : ""}`}>
                        <span className="product-name">{props.product.name}</span>
                    </Grid>
                </Grid>
                
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item container alignItems="center" className={`${props.product.comment==='Sold Out' ? "opaque" : ""}`}>
                        <Grid item xs={6} textAlign="start">
                            {isDiscounted(props.product.discount, props.product.oldPrice)}
                        </Grid>
                        <Grid item xs={6} textAlign="start">
                            <span className="product-discount">{props.product.discount}</span>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" className={`${props.product.comment==='Sold Out' ? "opaque" : ""}`}>
                        <ColorButton variant="contained" onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')} >
                                <Grid item container alignItems="center">
                                    <Grid item xs={6} textAlign="start">
                                        <span className="text-button">Buy Now</span>
                                    </Grid>
                                    <Grid item xs={6} textAlign="center">
                                        {inBudget(props.product.newPrice)}
                                    </Grid>
                                </Grid>
                        </ColorButton>
                    </Grid>
                </Grid>
                
            </Grid>
        
    );
}

export default WatchlistCard;
