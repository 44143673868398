import "../index.css";
import { Grid, Hidden } from '@mui/material';
import searchIcon from '../images/search.svg';
import searchPanelGirl from '../images/girl-in-background.png';

function SearchPanel() {
    return (
            <div className="search-panel">
                <Grid container item xs={12} alignItems="center" >
                    <Grid container item xs={12} sm={12} lg={8} md={7} spacing={3}>
                        <Grid container item xs={12}>
                            <Grid item xs={0.5} sm={0.5} md={1}></Grid>
                            <Grid item xs={11} sm={11} md={9.5} textAlign="center" >
                                <span className="search-panel-text" >Got something specific in mind?</span>
                            </Grid>
                            <Grid item xs={0.5} sm={0.5} md={1.5}></Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9} sm={9.5} textAlign="center" >
                            <Hidden mdDown>
                                <div className="relative">
                                    <input placeholder="Search for the best deal" className="search-input"></input>
                                    <img src={searchIcon} className="search-icon" alt="search-icon"></img>
                                </div>
                            </Hidden>
                            <Hidden mdUp>
                                <div className="relative">
                                    <input placeholder="Search" className="search-input"></input>
                                    <img src={searchIcon} className="search-icon" alt="search-icon"></img>
                                </div>                           
                            </Hidden>
                            </Grid>
                            <Grid item xs={2} sm={1.5}></Grid>
                        </Grid>
                        
                    </Grid>
                    <Hidden mdDown>
                        <Grid container item md={5} lg={4}>
                            <img src={searchPanelGirl} alt="search-panel-girl"></img>
                        </Grid>
                    </Hidden>

                </Grid>
                
            </div>
    );
}

export default SearchPanel;
