import "../../index.css";
import { styled } from '@mui/material/styles';
import { Grid, Box, Card, CardContent, CardActions, Autocomplete, TextField, CircularProgress, InputAdornment, Button } from '@mui/material';
import { useEffect, useState } from "react";
import axios from 'axios';

function SellerDemo() {

    const [ products, setProducts] = useState([]);
    const [ loading, setLoading] = useState(true);
    const [ product, setProduct ] = useState({});
    const [ originalPrice, setOriginalPrice ] = useState(0)
    const [ newPrice, setNewPrice ] = useState(0);
    const [ discountPercent, setDiscountPercent ] = useState(0);

    const AddButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#080028',
        border: '1px solid #080028',
        borderRadius: '5px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#080028',
        },
    }));

    const ClearButton = styled(Button)(({ theme }) => ({
        backgroundColor:'#ffffff',
        border: '1px solid #080028',
        borderRadius: '5px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        color: "#080028"
    }));

    useEffect(()=>{
        axios.get("https://akadinia.com/product/getProducts").then(response => { //localhost:4000
            setProducts(response.data);
            setLoading(false);
        });
    }, []);

    const renderPricesFields = (value) => {
        setProduct(value);
        setOriginalPrice(value.original_price);
        setNewPrice(value.new_price);
        setDiscountPercent(value.discount_percent);
    }

    const changeDiscount = (field, value) => {
        switch (field) {
            case 'value': { 
                setNewPrice(value);
                setDiscountPercent((((originalPrice-value)/originalPrice)*100).toFixed(2));
                break;
            }
            case 'percent': { 
                setDiscountPercent(value);
                setNewPrice(originalPrice-(value*originalPrice)/100);
                break;
            }
        }
    }

    const clear = (product) =>{
        setNewPrice(product.new_price);
        setDiscountPercent(product.discount_percent);
    }

    const addDiscount = (product) => {
        axios.put(`https://akadinia.com/product/updateProduct`, {id: product._id, new_price: newPrice, discount_percent: discountPercent}) //localhost:4000
        .then((response) => {
            window.location.reload(false);
        });
    }

    if(loading){
        return (
            <Grid item rowSpacing={6} container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                <CircularProgress />
            </Grid>
        )
    }

    return (<Box className="background" sx={{ flexGrow: 1 }}>
            <Grid item rowSpacing={6} container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                    <Grid item >
                        <div className="demo-page-title">Add a discount</div>
                    </Grid>
                    <Grid item>
                        <Card sx={{ minWidth: 400 }}>
                            <CardContent>
                                <Grid container direction="column" spacing={4}>
                                    <Grid item rowSpacing={2}>
                                        <Grid item><span className="demo-label">Select a Product</span></Grid>
                                        <Grid item>
                                            <Autocomplete
                                                options={products}
                                                autoHighlight
                                                onChange={(event, value) => renderPricesFields(value)}
                                                getOptionLabel={(option) => option.product_name}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props } key={option._id}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={option.photos[0]}
                                                        alt=""
                                                    />
                                                    {option.product_name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Product" variant="standard" />}
                                                />
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item rowSpacing={2}>
                                        <Grid item><span className="demo-label">Original Price</span></Grid>
                                        <Grid item>
                                            <TextField label="Original price" variant="standard" 
                                                value={originalPrice}
                                                disabled
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item rowSpacing={2}>
                                        <Grid item><span className="demo-label">Current Discount</span></Grid>
                                        <Grid container direction="row" alignItems="center" columnSpacing={4} >
                                            <Grid item>
                                                <TextField label="Discount value" variant="standard" 
                                                    value={newPrice}
                                                    onChange={(event) =>{changeDiscount('value', event.target.value);}}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                                    }}/>
                                            </Grid>
                                            <Grid item >
                                                <TextField label="Discount percent" variant="standard" 
                                                    value={discountPercent}
                                                    onChange={(event) =>{changeDiscount('percent', event.target.value);}}
                                                    InputProps={{
                                                        inputMode: 'numeric',
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}/>
                                                {/*<span className="demo-discount">{discountPercent}%</span>*/}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                
                            </CardContent>
                            <CardActions>
                                <ClearButton size="small" variant="contained" onClick={()=>clear(product)}>Clear</ClearButton>
                                <AddButton size="small" variant="contained" onClick={()=>addDiscount(product)}>Add Discount</AddButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>  
        </Box>);
}

export default SellerDemo;
