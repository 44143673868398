import "../index.css";
import { Grid, Hidden, Drawer, Alert, AlertTitle, TextField } from '@mui/material';
// import { useEffect } from "react";
import { useState } from "react";
import akadiniaLogo from '../images/akadinia-logo.svg';
import searchIcon from '../images/search.svg';
import akadiniaIcon from '../images/akadinia-icon.svg';
import circleWarning from '../images/circle-warning.svg';
import messengerIcon from '../images/messenger-icon.svg';
import bagIcon from '../images/bag.svg';
import userIcon from '../images/user.svg';
import menuIcon from '../images/menu.svg';

function Header() {
    const [alert, setAlert] = useState(true);
    /*useEffect(() => {
        const header = document.getElementById("myHeader");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                console.log(header.classList)
                header.classList.add("sticky");
            } else {
                console.log(header.classList)
                header.classList.remove("sticky");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);*/

    const [state, setState] = useState(false);
    
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    
        setState(open);
    };

    const renderAlert = (alert)=>{
        if(alert){
            return <div className="alert-panel">
                        <Grid container direction="column" item alignItems="center" textAlign="center" spacing={3}>
                            <Grid item alignItems="center" textAlign="center">
                                <img src={akadiniaIcon}></img>
                            </Grid>
                            <Grid item alignItems="center" textAlign="center">
                                <Grid item className="relative" textAlign="center" alignItems="center">
                                    <img className="warning-icon" src={circleWarning}></img>
                                    <span className="alert-panel-text">This platform is still under development.</span>
                                </Grid>
                                <Grid item >
                                    <span className="alert-panel-text">Instead, you can try the Akadinia Chatbot on Facebook Messenger. </span>
                                </Grid>
                            </Grid>
                            <Grid item className="relative" alignItems="center" textAlign="center"> {/** https://www.facebook.com/Akadinia/ */}
                                <a className="alert-panel-link" href="https://www.facebook.com/messages/t/112090244699695/">Akadinia Chatbot</a> 
                                <img className="messenger-icon" src={messengerIcon}></img>
                            </Grid>
                        </Grid>
                    </div>
                {/*<Alert severity="info">
                        <AlertTitle>This platform is under development. But, now you can start chatting with Akadinia chatbot on Facebook Messenger. <a className="akadinia-page" href='https://www.facebook.com/Akadinia/'>Akadinia Page</a> </AlertTitle>
                        <Grid container direction="column" spacing={1} >
                            <Grid item>
                                <span>Please enter your email so we can contact you once a platform is launched:</span>
                            </Grid>
                            <Grid item>
                                <TextField label="Email" variant="standard" />
                            </Grid>
                        </Grid>
                    </Alert>*/}
        } else{
            return <span></span>
        }
    }

    return (
        <>
            <Grid container direction="column" spacing={2}>
                <Grid item>{renderAlert(alert)}</Grid>

                <Grid container item spacing={1} alignItems="center" >
                    <Hidden lgDown>
                        <Grid item xs={1} onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')} >{/**onClick={()=>setAlert(!alert)} */}
                            <span className="navbar-item-activated" >Home</span>
                        </Grid>
                    </Hidden>
            
                    <Hidden lgDown>
                        <Grid item xs={1.3} onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')}>{/**onClick={()=>setAlert(!alert)} */}
                            <span className="navbar-item-disactivated" >Watchlist</span>
                        </Grid>
                    </Hidden>

                    <Hidden lgDown>
                        <Grid item xs={3} onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')}>{/**onClick={()=>setAlert(!alert)} */}
                            <span className="navbar-item-disactivated">Preferences</span>
                        </Grid>
                    </Hidden>

                    <Hidden lgUp>
                        <Grid item xs={1}>
                            <img className="pointer" src={menuIcon} alt="menu-icon" onClick={()=> window.open('https://www.facebook.com/messages/t/112090244699695/')} ></img>{/*onClick={toggleDrawer(true)}*/}
                        </Grid>
                        <Drawer
                            open={state}
                            onClose={toggleDrawer(false)}
                        >
                            Just some test
                        </Drawer>
                    </Hidden>

                    <Grid item xs={8.4} sm={8.4} md={8.4} lg={5.5} textAlign="start">
                        <img className="akadinia-logo" src={akadiniaLogo} alt="akadinia-logo"></img>
                    </Grid>

                    <Grid item xs= {1.3} sm={1.3} md={1.3} lg={0.5} textAlign="end">
                        <img src={searchIcon} alt="search-icon"></img>
                    </Grid>
                    {/*<Grid item xs= {1.5} sm={1} md={1} lg={0.5} textAlign="end">
                        <img src={bagIcon} alt="bag-icon"></img>
                    </Grid>*/}
                    <Grid item xs= {1.3} sm={1.3} md={1.3} lg={0.7} textAlign="end">
                        <img src={userIcon} alt="user-icon"></img>
                    </Grid>
                </Grid>
            </Grid>
            
        </>
    );
}

export default Header;
